import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Spinner from '../components/spinner.js'

export default ({ loading, course, episode, user, classNames = '' }) => {
    return (
        <div className={`${classNames} bg-gray-400 flex-center`}>
            <div
                className={`${
                    loading ? 'hidden' : ''
                } transition-opacity opacity-100 flex flex-col text-center px-12 py-6 shadow-lg rounded bg-white z-10`}>
                <p className="text-center my-2 text-gray-800 bg-white">
                    This episode is a part of a paid course <br />
                    <span className="font-medium text-lg">{course.title}</span>
                </p>
                <Link
                    to={course.url}
                    className={`
relative inline-block w-auto my-2 py-2 px-3 border border-transparent hover:bg-gray-600 rounded-md text-white font-semibold bg-gray-800 hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95`}>
                    <span className="absolute left-0 inset-y pl-3">
                        <svg
                            className="h-5 w-5 text-gray-500"
                            fill="currentColor"
                            viewBox="0 0 20 20">
                            <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>

                    <span className="pl-0">View Course</span>
                </Link>
                {!user && (
                    <Link
                        to={`/login?redirectTo=${episode.url}`}
                        className={`inline my-2 w-auto text-gray-600 font-medium focus:outline-none focus:shadow-outline hover:text-gray-900 underline sm:text-sm sm:leading-5`}>
                        <span className="">Bought already? Login</span>
                    </Link>
                )}
            </div>
            <div className="absolute inset-0 flex-center">
                <Spinner loading={loading} />
                <Img
                    className="h-full w-1/2 opacity-25"
                    fluid={episode.thumbnailImg.childImageSharp.fluid}
                />
            </div>
        </div>
    )
}
